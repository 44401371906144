import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const CgvPage = () => (
    <Layout>
        <SEO title="CGV" />
        <Container>
            <h1 style={{ marginTop: "2rem" }}>Conditions Générales de Vente</h1>

        </Container>
    </Layout >
)

export default CgvPage